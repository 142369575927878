import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('tasks?page=' + page,  {params});
    }, 
    show(id) {
        return Service().get('tasks/show/'+id);
    },    
    create(params) {
        return Service().post('tasks/create', params);
    },
    update(id, params) {
        return Service().post('tasks/update/'+id, params);
    },
    delete(id) {
        return Service().post('tasks/delete/'+id);
    }
}